import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "./partitions/dropdwon";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import SignAdminDD from "./partitions/signAdminDD";

export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchResults } = useSelector(state => state.searchResult)
  const [searchWord, setSearchWord] = useState('');

  // Handle Search Results

  const handleInputChange = (event) => {
    setSearchWord(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`/search-results/${searchWord}`);
    window.location.reload();
  };

  //for dropdown
  const [dropdown, setdropdown] = useState(false)

  const [mouseHover, setMouseHover] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdown && !dropdownRef.current.contains(event.target)) {
        setdropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);


  const handleMouseEnter = () => {
    setMouseHover(true);
  };

  const handleMouseLeave = () => {
    setMouseHover(false);
  };

  const toggleDropdown = () => {
    setdropdown(!dropdown)
  }


  //for signAdminDD
  const [signAdminDD, setsignAdminDD] = useState(false)

  const [mouseHoverAdmin, setMouseHoverAdmin] = useState(false);
  const dropdownRefAdmin = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (signAdminDD && !dropdownRefAdmin.current.contains(event.target)) {
        setsignAdminDD(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [signAdminDD]);


  const handleMouseEnterAdmin = () => {
    setMouseHoverAdmin(true);
  };

  const handleMouseLeaveAdmin = () => {
    setMouseHoverAdmin(false);
  };

  const togglesignAdminDD = () => {
    setsignAdminDD(!signAdminDD)
  }

  return (
    <div className="header">
      <div className="topSec">
        <div className="logoSec">
          <div className="name">مركز دراسات الخليج والقرن الافريقي <br></br>Gulf and Horn of Africa Studies Center
          </div>
          <div className="logo"><Link to={'/'}><img alt="logo" src="/imgs/logo-removebg-preview.png"></img></Link>
            <div className="secDrobdwon">
              <i className="adminArrow down" onClick={togglesignAdminDD}></i>
              <div
                ref={dropdownRefAdmin}
                onMouseEnter={handleMouseEnterAdmin}
                onMouseLeave={handleMouseLeaveAdmin}
              >
                {signAdminDD && (mouseHoverAdmin || dropdownRefAdmin.current) && <SignAdminDD />}
              </div>
            </div>
          </div>
        </div>
        <div className="search">
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder="البحث في الموقع" value={searchWord} onChange={handleInputChange}></input>
            <button type="submit"><img src="/imgs/search-iqon.png" class="searchIqon"></img></button>
          </form>
        </div>
      </div>
      <div className="bottomSec">
        <div className="navbar">
          <div className="navSec">عن المركز</div>
          <Link to={'/articles'}> <div className="navSec">مقالات</div></Link>
          <div className="toggle">
            <div className="navSec" onClick={toggleDropdown}>إصدارات المركز<i class="arrow down"></i></div>
            <div
              ref={dropdownRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {dropdown && (mouseHover || dropdownRef.current) && <Dropdown />}
            </div>
          </div>
          <div className="navSec">تواصل معنا</div>
        </div>
      </div>
    </div>
  )
}