import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import JoditEditor from 'jodit-react';
import { fetchArticles, updateArticles } from '../../../redux/apiCalls/articleApiCall';
import { RotatingLines } from 'react-loader-spinner';

export default function UpdateArticleScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { loading, isArticleCreated } = useSelector(state => state.article);
    const { articles } = useSelector(state => state.article)
    const editor = useRef(null);

    useEffect(() => {
        dispatch(fetchArticles())
    }, [])

    const article = articles.find((item) => item._id === id);

    const [title, setTitle] = useState(article.title)
    const [summary, setSummary] = useState(article.summary)
    const [image, setImage] = useState(null)
    const [topic, setTopic] = useState(article.topic)

    const subjectId = article._id;


    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (topic.trim() === "") return toast.error("الموضوع مطلوب");

        const formData = new FormData();
        formData.append('title', title);
        formData.append('summary', summary);
        formData.append('image', image);
        formData.append('topic', topic);

        dispatch(updateArticles(subjectId, formData));
    }

    useEffect(() => {
        if (isArticleCreated) {
            toast.success("تم التعديل بنجاح");
            navigate("/dashboard/articles");
        }
    }, [isArticleCreated, navigate]);


    return (
        <div className='updateScreen' key={article?.id}>
            <div className="formTit">تعديل مقال</div>
            <form className='updateForm' onSubmit={onSubmit}>
                <label htmlFor='title'>العنوان</label>
                <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} /><br></br>
                <label htmlFor='summary'>نبذة</label>
                <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} /><br></br>
                <img src={article?.image.url}></img>
                <label htmlFor='image'>الصورة:</label>
                <input type='file' id='image' name='image' onChange={(e) => setImage(e.target.files[0])} /><br></br>
                <label htmlFor='topic'>الموضوع</label>
                <JoditEditor ref={editor} id='topic' name='topic' placeholder='أدخل الموضوع.....' value={topic} onChange={content => setTopic(content)} /><br></br>
                <button className='sbmtBtn' type='submit'>
                    {
                        loading ? <RotatingLines
                            visible={true}
                            height="30"
                            width="30"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        /> : "حفظ التعديلات"
                    }
                </button>
            </form>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
