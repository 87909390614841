import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/authSlice";
import { articleReducer } from "./slices/articleSlice";
import { researchReducer } from "./slices/researchSlice";
import { searchResultReducer } from "./slices/searchResultsSlice";


const store = configureStore({
    reducer:{
        auth: authReducer,
        article: articleReducer,
        research: researchReducer,
        searchResult: searchResultReducer,
    }
});

export default store;