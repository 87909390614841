import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser';
import { fetchArticles } from "../../redux/apiCalls/articleApiCall";
import moment from "moment";
import { Link } from "react-router-dom";

export default function LastArticles() {
    const dispatch = useDispatch();
    const { articles } = useSelector(state => state.article)

    useEffect(() => {
        dispatch(fetchArticles())
    }, [])

    const lastThreeArticles = articles.slice(-3)
    return (
        <div className="articles" >
            {lastThreeArticles.map((item) => (
                <div className="art" key={item.id}>
                    <div className="date">{moment(item?.date).format('LL')}</div>
                    <div className="title">{parse(`${item?.title}`)}</div>
                    <div className="brief">{parse(`${item?.summary}`)}</div>
                    <Link className='button' to={`/article/${item?._id}`}>المقال كاملا</Link>
                </div>
            ))}
        </div>
    )
}
