import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner'
import JoditEditor from 'jodit-react';
import { addArticles } from '../../../redux/apiCalls/articleApiCall';


export default function AddArticleScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, isArticleCreated } = useSelector(state => state.article);
    const editor = useRef(null);

    const [title, setTitle] = useState("");
    const [summary, setSummary] = useState("");
    const [image, setImage] = useState(null);
    const [topic, setTopic] = useState("");


    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (topic.trim() === "") return toast.error("الموضوع مطلوب");

        const formData = new FormData();
        formData.append('title', title);
        formData.append('summary', summary);
        formData.append('image', image);
        formData.append('topic', topic);

        dispatch(addArticles(formData));
    }


    useEffect(() => {
        if (isArticleCreated) {
            navigate("/dashboard/articles");
        }
    }, [isArticleCreated, navigate]);

    return (
        <div className='updateScreen'>
            <div className="formTit">إضافة مقال</div>
            {
                <form className='updateForm' onSubmit={onSubmit}>
                    <label htmlFor='title'>العنوان:</label>
                    <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} /><br></br>
                    <label htmlFor='summary'>نبذة:</label>
                    <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} /><br></br>
                    <label htmlFor='image'>الصورة:</label>
                    <input type='file' id='image' name='image' onChange={(e) => setImage(e.target.files[0])} /><br></br>
                    <label htmlFor='topic'>الموضوع:</label>
                    <JoditEditor ref={editor} id='topic' name='topic' placeholder='أدخل الموضوع.....' value={topic} onChange={content => setTopic(content)} /><br></br>
                    <button className='sbmtBtn' type='submit'>
                        {
                            loading ? <RotatingLines
                                visible={true}
                                height="30"
                                width="30"
                                color="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            /> : "أضف"
                        }
                    </button>
                </form>
            }
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
