import React from 'react'
import { Link } from 'react-router-dom'

export default function Dropdown() {
    return (
        <div>
            <div className="dropdown">
                <ul>
                    <Link className="ddA" to={'/research/arabian-gulf'}><li className="item">الخليج العربي</li></Link>
                    <Link className="ddA" to={'/research/horn-of-africa'}><li className="item">القرن الأفريقي</li></Link>
                </ul>
            </div>
        </div>
    )
}
