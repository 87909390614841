import { createSlice } from "@reduxjs/toolkit";

// searchResultSlice states and actions 
const searchResultSlice = createSlice({
    name:"searchResult",
    initialState: {
        searchResults: [],
        searchResult: null,
        loading: false,
        isResultsAppear: false,
    },
    reducers: {
        setSearchResults(state, action) {
            state.searchResults = action.payload;
        },
        setSearchResult(state, action) {
            state.searchResult = action.payload;
        },
        setLoading(state) {
            state.loading = true;
        },
        clearLoading(state) {
            state.loading = false;
        },
        setIsResultsAppear(state) {
            state.isResultsAppear = true;
            state.loading = false;
        },
        clearIsResultsAppear(state) {
            state.isResultsAppear = false;
        },
    }
});

const searchResultReducer = searchResultSlice.reducer;
const searchResultActions = searchResultSlice.actions;

export {searchResultActions, searchResultReducer}