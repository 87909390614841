import { createSlice } from "@reduxjs/toolkit";

// articleSlice states and actions 
const articleSlice = createSlice({
    name:"article",
    initialState: {
        articles: [],
        article: null,
        loading: false,
        isArticleCreated: false,
    },
    reducers: {
        setArticles(state, action) {
            state.articles = action.payload;
        },
        setArticle(state, action) {
            state.article = action.payload;
        },
        setLoading(state) {
            state.loading = true;
        },
        clearLoading(state) {
            state.loading = false;
        },
        setIsArticleCreated(state) {
            state.isArticleCreated = true;
            state.loading = false;
        },
        clearIsArticleCreated(state) {
            state.isArticleCreated = false;
        },
        deleteArticle(state, action) {
            state.articles = state.articles.filter(i => i._id !== action.payload);
        },
    }
});

const articleReducer = articleSlice.reducer;
const articleActions = articleSlice.actions;

export {articleActions, articleReducer}