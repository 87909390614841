import React, { useState, useEffect, useRef } from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import { showArticleSubject } from "../redux/apiCalls/articleApiCall";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    TumblrIcon,
    TelegramIcon,
    LinkedinIcon,
    FacebookIcon,
    TwitterIcon
} from "react-share";

export const Article = () => {
    const dispatch = useDispatch();
    const { article } = useSelector(state => state.article)
    const { id } = useParams();
    const currentUrl = window.location.href;

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(showArticleSubject(id))
    }, [id]);

    return (
        <div className="articles">
            <Header />
            {
                <div className="content" key={article?.id}>
                    <div className="artTitle">{parse(`${article?.title}`)}</div>
                    <div className="artDate">{moment(article?.date).format('LL')}</div>
                    <div className="artContent">
                        <img className="subImg" alt={article?.image.original_filename} src={article?.image.url}></img>
                        <div className="topic">{parse(`${article?.topic}`)}</div>
                    </div>
                    <div className="share">
                        <div className="tit">شارك المقال: </div>
                        <div className="shareSocial">
                            <TumblrShareButton url={currentUrl}>
                                <TumblrIcon size={50} />
                            </TumblrShareButton>
                            <LinkedinShareButton url={currentUrl}>
                                <LinkedinIcon size={50} />
                            </LinkedinShareButton>
                            <WhatsappShareButton url={currentUrl}>
                                <WhatsappIcon size={50} />
                            </WhatsappShareButton>
                            <TelegramShareButton url={currentUrl}>
                                <TelegramIcon size={50} />
                            </TelegramShareButton>
                            <FacebookShareButton url={currentUrl}>
                                <FacebookIcon size={50} />
                            </FacebookShareButton>
                            <TwitterShareButton url={currentUrl}>
                                <TwitterIcon size={50} />
                            </TwitterShareButton>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}