import React, { useState, useEffect, useRef } from "react";

export const Footer = () => {
    return (
        <div className="footer">
            <div className="topSec">
                <div className="logoSec">
                    <div className="logo"><img alt="logo" src="/imgs/logo-removebg-preview.png"></img></div>
                    <div className="name">مركز دراسات الخليج والقرن الافريقي <br></br>Gulf and Horn of Africa Studies Center
                    </div>
                </div>
                <div className="socialmediaSec">
                    <a href="https://www.facebook.com/people/GHASC/61559501591696/?mibextid=LQQJ4d&rdid=7d3ISEsAFmNC7qIt&share_url=https%253A%252F%252Fwww.facebook.com%252Fshare%252Fq4B5tkhDjmUeVMKq%252F%253Fmibextid%253DLQQJ4d"><img src="/imgs/f.png" className="f"></img></a>
                    <a href="#"><img src="/imgs/i.png" className="i"></img></a>
                    <a href="https://twitter.com/GHASC_"><img src="/imgs/x.png" className="x"></img></a>
                    <a href="#"><img src="/imgs/y.png" className="y"></img></a>
                </div>
            </div>
            <div className="bottomSec">
                <div>© All rights reserved to GHASC.</div>
                <div>Designed and developed by <a href="https://najm-tech.onrender.com/">Najm Tech</a>.</div>
            </div>
        </div>
    )
}