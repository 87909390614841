import React, { useEffect, useRef, useState } from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { logoutUser } from "../../redux/apiCalls/authApiCall";
import { useDispatch } from "react-redux";


export const SidebarDash = () => {
    const dispatch = useDispatch();


    //for dropdown
    const [dropdown, setdropdown] = useState(false)

    const [mouseHover, setMouseHover] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdown && !dropdownRef.current.contains(event.target)) {
                setdropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdown]);


    const handleMouseEnter = () => {
        setMouseHover(true);
    };

    const handleMouseLeave = () => {
        setMouseHover(false);
    };

    const toggleDropdown = () => {
        setdropdown(!dropdown)
    }

    return (
        <div className="sidebar">
            <div className="fixed">
                <div className="DBTitle">
                    <div className="text-wrapper-3">Dashboard</div>
                    <img className="dashboard-customize" alt="Dashboard customize" src="/imgs/55.png" />
                </div>
                <ul className="dashboardList">
                    <Link className="navAdmin" to={"/dashboard/articles"}>
                        <li className="div-wrapper">
                            المقالات
                        </li>
                    </Link>
                    <li className="div-wrapper" onClick={toggleDropdown}>
                        الأبحاث
                    </li>
                    <div
                        ref={dropdownRef}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {dropdown && (mouseHover || dropdownRef.current) &&
                            <div className="dropdown">
                                <Link className="nav" to={'/dashboard/research/horn-of-africa'}><div className="hf">أبحاث قسم القرن الافريقي </div></Link>
                                <Link className="nav" to={'/dashboard/research/arabian-gulf'}><div className="ag">أبحاث قسم الخليج العربي</div></Link>
                            </div>
                        }
                    </div>
                </ul>
                <div className="bottomPart">
                    <Link className="settings" to={'/settings'}>
                        الإعدادات
                    </Link>
                    <div className="signout" onClick={() => dispatch(logoutUser())}>
                        تسجيل الخروج
                    </div>
                </div>
            </div>
        </div>
    )
}