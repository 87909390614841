import request from "../../utils/request";
import { toast } from "react-toastify";
import { searchResultActions } from "../slices/searchResultsSlice";

// Fetch SearchResult
export function fetchSearchResults(search) {
    return async (dispatch) => {
        try {
            dispatch(searchResultActions.setLoading());
            const { data } = await request.get(`/search-results/${search}`)
            dispatch(searchResultActions.setSearchResults(data));
            dispatch(searchResultActions.setIsResultsAppear());
            localStorage.setItem("searchResult", JSON.stringify(data));
            setTimeout(() => dispatch(searchResultActions.clearIsResultsAppear()), 2000);
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Fetch Research SearchResult
export function fetchResearchSearchResults(search) {
    return async (dispatch) => {
        try {
            const { data } = await request.get(`/search-results/research/${search}`)
            dispatch(searchResultActions.setSearchResults(data));
            localStorage.setItem("searchResult", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Fetch Articles SearchResult
export function fetchArticlesSearchResults(search) {
    return async (dispatch) => {
        try {
            const { data } = await request.get(`/search-results/articles/${search}`)
            dispatch(searchResultActions.setSearchResults(data));
            localStorage.setItem("searchResult", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}
