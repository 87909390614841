import React, { useState, useEffect, useRef } from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import { showArticleSubject } from "../redux/apiCalls/articleApiCall";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import { showAresearchSubject } from "../redux/apiCalls/researchApiCall";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

export const Research = () => {
    const dispatch = useDispatch();
    const { aresearch } = useSelector(state => state.research)
    const { id } = useParams();
    const currentUrl = window.location.href;

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(showAresearchSubject(id))
    }, [id]);

    return (
        <div className="articles">
            <Header />
            {
                <div className="content" key={aresearch?.id}>
                    <div className="artTitle">{parse(`${aresearch?.title}`)}</div>
                    <div className="artDate">تاريخ النشر: {parse(`${aresearch?.publishingdate}`)}</div>
                    <div className="for">{aresearch?.authors}</div>
                    <div className="for">{parse(`${aresearch?.institution}`)}</div>
                    <div className="artContent">
                        <div className="cont">
                            {parse(`${aresearch?.topic}`)}
                        </div>
                        {(aresearch?.pdf.url !== "") ? (
                            <div className="pdfSec">
                                <object data={parse(`${aresearch?.pdf?.url}`)} type="application/pdf">
                                    <p>This browser does not support PDFs.</p>
                                </object>
                                <a href={parse(`${aresearch?.pdf.url}`)}>تحميل ملف البحث</a>
                            </div>
                        ) : (<div></div>)}
                        <div className="source">المصادر: {parse(`${aresearch?.source}`)}</div>
                    </div>
                    <div className="share">
                        <div className="tit">شارك البحث: </div>
                        <div className="shareSocial">
                            <div className="shareSocial">
                                <TumblrShareButton url={currentUrl}>
                                    <TumblrIcon size={50} />
                                </TumblrShareButton>
                                <LinkedinShareButton url={currentUrl}>
                                    <LinkedinIcon size={50} />
                                </LinkedinShareButton>
                                <WhatsappShareButton url={currentUrl}>
                                    <WhatsappIcon size={50} />
                                </WhatsappShareButton>
                                <TelegramShareButton url={currentUrl}>
                                    <TelegramIcon size={50} />
                                </TelegramShareButton>
                                <FacebookShareButton url={currentUrl}>
                                    <FacebookIcon size={50} />
                                </FacebookShareButton>
                                <TwitterShareButton url={currentUrl}>
                                    <TwitterIcon size={50} />
                                </TwitterShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}