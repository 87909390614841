import request from "../../utils/request";
import { toast } from "react-toastify";
import { articleActions } from "../slices/articleSlice";

// Fetch Article
export function fetchArticles() {
    return async (dispatch) => {
        try {
            const { data } = await request.get("/articles")
            dispatch(articleActions.setArticles(data));
            localStorage.setItem("article", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Show Article Subject
export function showArticleSubject(id) {
    return async (dispatch) => {
        try {
            const { data } = await request.get(`/articles/${id}`)
            dispatch(articleActions.setArticle(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Update Articles
export function updateArticles(subjectId, updates) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userFile"))
            const token = user.token;
            dispatch(articleActions.setLoading());
            const { data } = await request.put(`/adminDashboard/articles/${subjectId}`, updates, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(articleActions.setArticle(data));
            dispatch(articleActions.setIsArticleCreated());
            setTimeout(() => dispatch(articleActions.clearIsArticleCreated()), 2000);
            toast.success("تم التعديل بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(articleActions.clearLoading());
        }
    }
}

// Delete Articles
export function deleteArticles(subjectId) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userFile"))
            // const userId = user._id;
            const token = user.token;
            console.log(subjectId)
            const { data } = await request.delete(`/adminDashboard/articles/${subjectId}`, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(articleActions.deleteArticle(data.subjectId));
            toast.success("تم الحذف بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Add Articles
export function addArticles(info) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userFile"))
            const token = user.token;
            dispatch(articleActions.setLoading());
            await request.post("/adminDashboard/addArticle", info, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(articleActions.setIsArticleCreated());
            setTimeout(() => dispatch(articleActions.clearIsArticleCreated()), 2000);
            toast.success("تمت الإضافة بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(articleActions.clearLoading());
        }
    }
}