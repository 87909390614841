import React, { useState } from "react";
import { Footer } from "./../footer";
import { Header } from "./../header";
import "../../App.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginUser } from "../../redux/apiCalls/authApiCall";



export const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const dispatch = useDispatch();
    // Submit Admin
    const onSubmit = (e) => {
        e.preventDefault();
        if (email.trim() === "") return toast.error("البريد الالكتروني مطلوب");
        if (password.trim() === "") return toast.error("كلمة المرور مطلوبة");

        dispatch(loginUser({ email, password }))
    }

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);


    return (
        <div className="login">
            <div className="div-2">
                <Header />
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={true}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <div className="text-wrapper-2">تسجيل دخول الأدمن</div>
                <form onSubmit={onSubmit} className="overlap-2">
                    <label htmlFor="email" className="text-wrapper-3">البريد الالكتروني:</label>
                    <input type="email" id="email" name="email" autoComplete="on" className="rectangle-2" placeholder="البريد الالكتروني" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <label htmlFor="password" className="text-wrapper-4">كلمة المرور:</label>
                    <input type="password" id="password" name="password" autoComplete="on" className="rectangle" placeholder="كلمة المرور" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <button className="text-wrapper-5 div-wrapper" type='submit'>تسجيل الدخول</button>
                </form>
            </div>
            <Footer />
        </div>
    );
};
