import { createSlice } from "@reduxjs/toolkit";

// researchSlice states and actions 
const researchSlice = createSlice({
    name:"aresearch",
    initialState: {
        research: [],
        aresearch: null,
        loading: false,
        isAresearchCreated: false,
    },
    reducers: {
        setResearch(state, action) {
            state.research = action.payload;
        },
        setAresearch(state, action) {
            state.aresearch = action.payload;
        },
        setLoading(state) {
            state.loading = true;
        },
        clearLoading(state) {
            state.loading = false;
        },
        setIsAresearchCreated(state) {
            state.isAresearchCreated = true;
            state.loading = false;
        },
        clearIsAresearchCreated(state) {
            state.isAresearchCreated = false;
        },
        deleteAresearch(state, action) {
            state.research = state.research.filter(i => i._id !== action.payload);
        },
    }
});

const researchReducer = researchSlice.reducer;
const researchActions = researchSlice.actions;

export {researchActions, researchReducer}