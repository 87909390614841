import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logoutUser } from '../../redux/apiCalls/authApiCall';
import { Link } from 'react-router-dom';

export default function SignAdminDD() {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)
    return (
            <div>
                <ul className="signAdminDD">
                    {user ?
                        <>
                            <li className='userEmail'>
                                {user?.email}
                            </li>
                            <br></br><br></br>
                            <Link className="ddA" to={'/dashboard'}>
                                <li className='click'>
                                    لوحة التحكم
                                </li>
                            </Link>
                            <Link className="ddA" to={'/settings'}>
                                <li className='click'>
                                    الإعدادات
                                </li>
                            </Link>
                            <li onClick={() => dispatch(logoutUser())} className='click'>
                                تسجيل الخروج
                            </li>
                        </> : (
                            <>
                                <Link className="ddA" to={'/login'}><li className='click'>الدخول كمشرف</li></Link>
                            </>
                        )
                    }
                </ul>
            </div>
    )
}
