import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Switch, Link, Navigate } from "react-router-dom";
import { LandingPage } from './pages/landingPage';
import { Articles } from './pages/articles';
import { ArabianGulf } from './pages/arabianGulf';
import { HornOfAfrica } from './pages/hornOfAfrica';
import { Article } from './pages/article';
import { SearchResults } from './pages/searchResults';
import { Research } from './pages/research';
import { useSelector } from 'react-redux';
import { DashboardArticles } from './pages/dashboard/dashboardArticles';
import AddArticleScreen from './pages/dashboard/partitions/addArticleScreen';
import { Login } from './pages/dashboard/login';
import { DashboardResearchAg } from './pages/dashboard/dashboardResearchAg';
import { DashboardResearchHa } from './pages/dashboard/dashboardResearchHa';
import AddResearchScreen from './pages/dashboard/partitions/addResearchScreen';
import UpdateArticleScreen from './pages/dashboard/partitions/updateArticleScreen';
import UpdateResearchScreen from './pages/dashboard/partitions/updateResearchScreen';
import { Settings } from './pages/dashboard/settings';

function App() {
  const { user } = useSelector(state => state.auth)

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/articles" exact element={<Articles />} />
          <Route path="/article/:id" exact element={<Article />} />
          <Route path="/research/arabian-gulf" exact element={<ArabianGulf />} />
          <Route path="/research/horn-of-africa" exact element={<HornOfAfrica />} />
          <Route path="/research/:id" exact element={<Research />} />
          <Route path="/search-results/:search" exact element={<SearchResults />} />
          <Route path="/login" exact element={!user ? <Login /> : <Navigate to="/dashboard/articles" />} />
          <Route path="/settings" exact element={user?.isAdmin ? <Settings /> : <Navigate to="/" />} />
          <Route path="/dashboard" exact element={!user ? <Navigate to="/login" /> : user?.isAdmin ? <Navigate to="/dashboard/articles" /> : <Navigate to="/" />} />
          <Route path="/dashboard/articles" exact element={user?.isAdmin ? <DashboardArticles /> : <Navigate to="/" />} />
          <Route path="/dashboard/research/arabian-gulf" exact element={user?.isAdmin ? <DashboardResearchAg /> : <Navigate to="/" />} />
          <Route path="/dashboard/research/horn-of-africa" exact element={user?.isAdmin ? <DashboardResearchHa /> : <Navigate to="/" />} />
          <Route path="/dashboard/addArticle" exact element={user?.isAdmin ? <AddArticleScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/addResearch" exact element={user?.isAdmin ? <AddResearchScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/updateArticle/:id" exact element={user?.isAdmin ? <UpdateArticleScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/updateResearch/:id" exact element={user?.isAdmin ? <UpdateResearchScreen /> : <Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
