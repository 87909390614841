import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import JoditEditor from 'jodit-react';
import { fetchArticles, updateArticles } from '../../../redux/apiCalls/articleApiCall';
import { fetchAllResearch, updateAresearch } from '../../../redux/apiCalls/researchApiCall';
import { RotatingLines } from 'react-loader-spinner';

export default function UpdateResearchScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { research } = useSelector(state => state.research)
    const { loading, isAresearchCreated } = useSelector(state => state.research);
    const editor = useRef(null);

    useEffect(() => {
        dispatch(fetchAllResearch())
    }, [])

    const aResearch = research.find((item) => item._id === id);

    const [title, setTitle] = useState(aResearch.title)
    const [authors, setAuthors] = useState(aResearch.authors)
    const [summary, setSummary] = useState(aResearch.summary)
    const [topic, setTopic] = useState(aResearch.topic)
    const [pdf, setPdf] = useState(null)
    const [keywords, setKeywords] = useState(aResearch.keywords)
    const [section, setSection] = useState(aResearch.section)
    const [publishingdate, setPublishingdate] = useState(aResearch.publishingdate)
    const [institution, setInstitution] = useState(aResearch.institution)
    const [source, setSource] = useState(aResearch.source)

    const subjectId = aResearch._id;


    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (authors.trim() === "") return toast.error("أسماء المؤلفون مطلوبة");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (topic.trim() === "") return toast.error("الموضوع مطلوب");
        if (section.trim() === "") return toast.error("القسم مطلوب");
        if (publishingdate.trim() === "") return toast.error("تاريخ النشر مطلوب");
        if (institution.trim() === "") return toast.error("اسم المؤسسة مطلوب");
        if (pdf && pdf.type !== 'application/pdf') return toast.error("يجب عليك اختيار ملف PDF");

        const formData = new FormData();
        formData.append('title', title);
        formData.append('authors', authors);
        formData.append('summary', summary);
        formData.append('topic', topic);
        formData.append('pdf', pdf);
        formData.append('keywords', keywords);
        formData.append('section', section);
        formData.append('publishingdate', publishingdate);
        formData.append('institution', institution);
        formData.append('source', source);

        dispatch(updateAresearch(subjectId, formData));
    }

    useEffect(() => {
        if (isAresearchCreated) {
            navigate("/dashboard/research/arabian-gulf");
        }
    }, [isAresearchCreated, navigate]);

    // Select Section
    const handleChange = (event) => {
        const newValue = event.target.value;
        setSection(newValue);
    };

    return (
        <div className='updateScreen' key={aResearch.id}>
            <div className="formTit">تعديل بحث</div>
            <form className='updateForm' onSubmit={onSubmit}>
                <label htmlFor='title'>العنوان:</label>
                <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} />
                <label htmlFor='summary'>نبذة:</label>
                <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} />
                <label htmlFor='topic'>الموضوع:</label>
                <JoditEditor ref={editor} id='topic' name='topic' placeholder='أدخل الموضوع.....' value={topic} onChange={content => setTopic(content)} />
                <label htmlFor='pdf'>ملف البحث:</label>
                <input type='file' id='pdf' name='pdf' onChange={(e) => setPdf(e.target.files[0])} />
                <label htmlFor='authors'>المؤلفون:</label>
                <input type='text' id='authors' name='authors' placeholder='أدخل أسماء المؤلفون.....' value={authors} onChange={(e) => setAuthors(e.target.value)} />
                <label htmlFor='keywords'>الكلمات المفتاحية:</label>
                <input type='text' id='keywords' name='keywords' placeholder='أدخل الكلمات المفتاحية.....' value={keywords} onChange={(e) => setKeywords(e.target.value)} />
                <label htmlFor='section'>القسم:</label>
                <select name="section" id="section" value={section} onChange={handleChange}>
                    <option value={""} ></option>
                    <option value={"الخليج العربي"} >الخليج العربي</option>
                    <option value={"القرن الأفريقي"} >القرن الأفريقي</option>
                </select>
                <label htmlFor='publishingdate'>تاريخ النشر:</label>
                <input type="date" id="birthDate" name="birthDate" required value={publishingdate} onChange={(e) => setPublishingdate(e.target.value)} />
                <label htmlFor='institution'>المؤسسة:</label>
                <input type='text' id='institution' name='institution' placeholder='أدخل اسم المؤسسة.....' value={institution} onChange={(e) => setInstitution(e.target.value)} />
                <label htmlFor='source'>المصادر:</label>
                <input type='text' id='source' name='source' placeholder='أدخل المصادر.....' value={source} onChange={(e) => setSource(e.target.value)} />

                <button className='sbmtBtn' type='submit'>
                    {
                        loading ? <RotatingLines
                            visible={true}
                            height="30"
                            width="30"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        /> : "حفظ التعديلات"
                    }
                </button>
            </form>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
