import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner'
import JoditEditor from 'jodit-react';
import { addAresearch } from '../../../redux/apiCalls/researchApiCall';


export default function AddResearchScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, isAresearchCreated } = useSelector(state => state.research);
    const editor = useRef(null);

    const [title, setTitle] = useState("")
    const [authors, setAuthors] = useState("")
    const [summary, setSummary] = useState("")
    const [topic, setTopic] = useState("")
    const [pdf, setPdf] = useState(null)
    const [keywords, setKeywords] = useState("")
    const [section, setSection] = useState("")
    const [publishingdate, setPublishingdate] = useState("")
    const [institution, setInstitution] = useState("")
    const [source, setSource] = useState("")


    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (authors.trim() === "") return toast.error("أسماء المؤلفون مطلوبة");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (topic.trim() === "") return toast.error("الموضوع مطلوب");
        if (section.trim() === "") return toast.error("القسم مطلوب");
        if (publishingdate.trim() === "") return toast.error("تاريخ النشر مطلوب");
        if (institution.trim() === "") return toast.error("اسم المؤسسة مطلوب");
        if (pdf && pdf.type !== 'application/pdf') return toast.error("يجب عليك اختيار ملف PDF");

        const formData = new FormData();
        formData.append('title', title);
        formData.append('authors', authors);
        formData.append('summary', summary);
        formData.append('topic', topic);
        formData.append('pdf', pdf);
        formData.append('keywords', keywords);
        formData.append('section', section);
        formData.append('publishingdate', publishingdate);
        formData.append('institution', institution);
        formData.append('source', source);


        dispatch(addAresearch(formData));
    }


    useEffect(() => {
        if (isAresearchCreated) {
            navigate("/dashboard/research/arabian-gulf");
        }
    }, [isAresearchCreated, navigate]);

    // Select Section
    const handleChange = (event) => {
        const newValue = event.target.value;
        setSection(newValue);
    };

    return (
        <div className='updateScreen'>
            <div className="formTit">إضافة بحث</div>
            {
                <form className='updateForm' onSubmit={onSubmit}>
                    <label htmlFor='title'>العنوان:</label>
                    <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} /><br></br>
                    <label htmlFor='summary'>نبذة:</label>
                    <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} /><br></br>
                    <label htmlFor='topic'>الموضوع:</label>
                    <JoditEditor ref={editor} id='topic' name='topic' placeholder='أدخل الموضوع.....' value={topic} onChange={content => setTopic(content)} /><br></br>
                    <label htmlFor='pdf'>ملف البحث:</label>
                    <input type='file' id='pdf' name='pdf' onChange={(e) => setPdf(e.target.files[0])} /><br></br>
                    <label htmlFor='authors'>المؤلفون:</label>
                    <input type='text' id='authors' name='authors' placeholder='أدخل أسماء المؤلفون.....' value={authors} onChange={(e) => setAuthors(e.target.value)} /><br></br>
                    <label htmlFor='keywords'>الكلمات المفتاحية:</label>
                    <input type='text' id='keywords' name='keywords' placeholder='أدخل الكلمات المفتاحية.....' onChange={(e) => setKeywords(e.target.value)} /><br></br>
                    <label htmlFor='section'>القسم:</label>
                    <select name="section" id="section" value={section} onChange={handleChange}>
                        <option value={""} ></option>
                        <option value={"الخليج العربي"} >الخليج العربي</option>
                        <option value={"القرن الأفريقي"} >القرن الأفريقي</option>
                    </select><br></br>
                    <label htmlFor='publishingdate'>تاريخ النشر:</label>
                    <input type="date" id="birthDate" name="birthDate" required onChange={(e) => setPublishingdate(e.target.value)} /><br></br>
                    <label htmlFor='institution'>المؤسسة:</label>
                    <input type='text' id='institution' name='institution' placeholder='أدخل اسم المؤسسة.....' onChange={(e) => setInstitution(e.target.value)} /><br></br>
                    <label htmlFor='source'>المصادر:</label>
                    <input type='text' id='source' name='source' placeholder='أدخل المصادر.....' onChange={(e) => setSource(e.target.value)} /><br></br>

                    <button className='sbmtBtn' type='submit'>
                        {
                            loading ? <RotatingLines
                                visible={true}
                                height="30"
                                width="30"
                                color="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            /> : "أضف"
                        }
                    </button>
                </form>
            }
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
