import React, { useState, useEffect, useRef } from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import { fetchResearchHA } from "../redux/apiCalls/researchApiCall";
import moment from 'moment';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const HornOfAfrica = () => {
    const dispatch = useDispatch();
    const { research } = useSelector(state => state.research)

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    useEffect(() => {
        window.onload = () => {
            window.scrollTo(0, 0);
        }
        dispatch(fetchResearchHA())
    }, []);
    return (
        <div className="articles">
            <Header />
            <div className="content">
                <div className="title">أبحاث قسم القرن الأفريقي</div>
                <div className="arts">
                    {research?.map((item) => (

                        <div className="art" key={item?.id}>
                            <div className="artTit"><Link to={`/research/${item?._id}`}>{parse(`${item?.title}`)}</Link></div>
                            <div className="artDate">{parse(`${item?.publishingdate}`)}</div>
                            <div className="artBrief">{parse(`${item?.summary}`)}</div>
                        </div>
                    ))}
                </div>

            </div>
            <Footer />
        </div>
    )
}