import React, { useEffect, useState } from "react";
import { Footer } from "../footer";
import { Header } from "../header";
import "../../App.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SidebarDash } from "./sidebarDash";
import swal from 'sweetalert'
import moment from 'moment';
import { ToastContainer } from "react-toastify";
import parse from 'html-react-parser';
import { deleteAresearch, fetchResearchHA } from "../../redux/apiCalls/researchApiCall";

export const DashboardResearchHa = () => {
    const dispatch = useDispatch();
    const { research } = useSelector(state => state.research)

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    useEffect(() => {
        dispatch(fetchResearchHA())
    }, [])

    return (
        <div className="dashboard">
            <SidebarDash />
            <div className="remain">
                <Header />
                <div className="mainCont">
                    <Link to={"/dashboard/addResearch"}>
                        <div className="overlap-9">
                            أضف بحث
                            <img className="create" alt="Create" src="/imgs/44.png" />
                        </div>
                    </Link>
                    <div className="text-wrapper-11">أبحاث قسم القرن الأفريقي</div>
                    <div className="cont">
                        {research.map((item) => (
                            <div className="overlap-10" key={item?.id}>
                                <div className="overlap-11">
                                    <div className="component">
                                        <Link to={`/dashboard/updateResearch/${item?._id}`}>
                                            <div className="overlap">
                                                <p className="text-wrapper">تعديل
                                                </p>
                                            </div>
                                        </Link>
                                        <Link to={`/research/${item?._id}`}>
                                            <div className="overlap1">
                                                <div className="text-wrapper">عرض
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="overlap2" onClick={() => {
                                            swal({
                                                title: "هل أنت متأكد",
                                                text: "بمجرد الحذف، لن تتمكن من الاستعادة!",
                                                icon: "warning",
                                                buttons: true,
                                                dangerMode: true,
                                            })
                                                .then((willDelete) => {
                                                    if (willDelete) {
                                                        dispatch(deleteAresearch(item?._id))
                                                    } else {
                                                        swal("لم يتم الحذف");
                                                    }
                                                });
                                        }}>
                                            <div className="text-wrapper1">حذف
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="date">{moment(item.date).format('LL')}</div>
                                <div className="overlap-12">
                                    <div className="text-wrapper-10">{parse(`${item.title}`)}</div>
                                </div>
                                <p className="p">
                                    {parse(`${item.summary}`)}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <Footer />
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={true}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </div >
        </div>
    );
};
