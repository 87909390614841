import request from "../../utils/request";
import { toast } from "react-toastify";
import { researchActions } from "../slices/researchSlice";

// Fetch All Research
export function fetchAllResearch() {
    return async (dispatch) => {
        try {
            const { data } = await request.get("/research/")
            dispatch(researchActions.setResearch(data));
            localStorage.setItem("research", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Fetch Research Arabian gulf
export function fetchResearchAg() {
    return async (dispatch) => {
        try {
            const { data } = await request.get("/research/arabian-gulf")
            dispatch(researchActions.setResearch(data));
            localStorage.setItem("research", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Fetch Research horn Of Africa
export function fetchResearchHA() {
    return async (dispatch) => {
        try {
            const { data } = await request.get("/research/horn-of-africa")
            dispatch(researchActions.setResearch(data));
            localStorage.setItem("research", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}



// Show Aresearch Subject
export function showAresearchSubject(id) {
    return async (dispatch) => {
        try {
            const { data } = await request.get(`/research/${id}`)
            dispatch(researchActions.setAresearch(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}



// Update A Research
export function updateAresearch(subjectId, updates) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userFile"))
            const token = user.token;
            dispatch(researchActions.setLoading());
            const { data } = await request.put(`/adminDashboard/research/${subjectId}`, updates, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(researchActions.setAresearch(data));
            dispatch(researchActions.setIsAresearchCreated());
            setTimeout(() => dispatch(researchActions.clearIsAresearchCreated()), 2000);
            toast.success("تم التعديل بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(researchActions.clearLoading());
        }
    }
}

// Delete Aresearch
export function deleteAresearch(subjectId) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userFile"))
            // const userId = user._id;
            const token = user.token;
            console.log(subjectId)
            const { data } = await request.delete(`/adminDashboard/research/${subjectId}`, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(researchActions.deleteAresearch(data.subjectId));
            toast.success("تم الحذف بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Add Aresearch
export function addAresearch(info) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userFile"))
            const token = user.token;
            dispatch(researchActions.setLoading());
            await request.post("/adminDashboard/addResearch", info, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(researchActions.setIsAresearchCreated());
            setTimeout(() => dispatch(researchActions.clearIsAresearchCreated()), 2000);
            toast.success("تمت الإضافة بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(researchActions.clearLoading());
        }
    }
}