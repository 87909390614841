import React, { useState, useEffect, useRef } from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import LastArticles from "./partitions/lastArticles";
import { Link } from "react-router-dom";

export const LandingPage = () => {
    return (
        <div className="landingPage">
            <Header />
            <div className="ghascImg">
                <div className="imgShade">
                    <div className="Title">
                        <div className="ar">مركز دراسات الخليج والقرن الافريقي</div>
                        <div className="en">Gulf and Horn of Africa Studies Center</div>
                        <div className="bottom">GHASC</div>
                    </div>
                </div>
                <img src="/imgs/hbg.png"></img>

            </div>
            <div className="articleSec">
                <div className="artTit">
                    <div className="Tit">آخر المقالات</div>
                    <div className="allArt"> <Link to={'/articles'}>كل المقالات</Link></div>
                </div>
                <LastArticles />
            </div>
            <div className="pubSec">
                <div className="pubTit">إصدارات المركز</div>
                <div className="sections">
                    <Link to={'/research/arabian-gulf'}><div className="sec">الخليج العربي</div></Link>
                    <Link to={'/research/horn-of-africa'}><div className="sec">القرن الأفريقي</div></Link>
                </div>
            </div>
            <Footer />
        </div>
    )
}