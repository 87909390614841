import React, { useState, useEffect, useRef } from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import moment from 'moment';
import parse from 'html-react-parser';
import { fetchArticles } from "../redux/apiCalls/articleApiCall";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const Articles = () => {
    const dispatch = useDispatch();
    const { articles } = useSelector(state => state.article)

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    useEffect(() => {
        window.onload = () => {
            window.scrollTo(0, 0);
        }
        dispatch(fetchArticles())
    }, []);
    return (
        <div className="articles">
            <Header />
            <div className="content">
                <div className="title">المقالات</div>
                <div className="arts">
                    {articles.map((item) => (
                        <div className="art" key={item?.id}>
                            <div className="artTit"><Link to={`/article/${item?._id}`}>{parse(`${item?.title}`)}</Link></div>
                            <div className="artDate">{moment(item?.date).format('LL')}</div>
                            <div className="artBrief">{parse(`${item?.summary}`)}</div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}