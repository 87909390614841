import { createSlice } from "@reduxjs/toolkit";

// authSlice states and actions 
const authSlice = createSlice({
    name:"auth",
    initialState: {
        user: localStorage.getItem("userFile") ?
        JSON.parse(localStorage.getItem("userFile")) : null,
    },
    reducers: {
        login(state, action) {
            state.user = action.payload;
        },
        logout(state) {
            state.user = null;
        },
    }
});

const authReducer = authSlice.reducer;
const authActions = authSlice.actions;

export {authActions, authReducer}