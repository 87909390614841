import React, { useState, useEffect, useRef } from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import { Link, useParams } from "react-router-dom";
import { fetchSearchResults } from "../redux/apiCalls/searchResultApiCall";
import parse from 'html-react-parser';
import { FallingLines } from 'react-loader-spinner'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import moment from "moment";

export const SearchResults = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { searchResults } = useSelector(state => state.searchResult)
    const { loading } = useSelector(state => state.searchResult);
    const { search } = useParams();

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    useEffect(() => {
        window.onload = () => {
            window.scrollTo(0, 0);
        }
        dispatch(fetchSearchResults(search))
    }, []);

    // Handle Search Results
    const [searchWord, setSearchWord] = useState('');

    const handleInputChange = (event) => {
        setSearchWord(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(`/search-results/${searchWord}`);
        window.location.reload();
    };

    return (
        <div className="SearchResults">
            <Header />
            <div className="searchSection">
                {
                    loading ? <div className="loading"><FallingLines
                        color="#0073CF"
                        width="100"
                        visible={true}
                        ariaLabel="falling-circles-loading"
                    /></div> :
                        <div className="searchWord">البحث عن:
                            <div className="word">{search}</div>
                        </div>
                }
                <div className="searchInput">
                    <div className="search">
                        <form onSubmit={handleSubmit}>
                            <input type="text" placeholder="البحث في الموقع" value={searchWord} onChange={handleInputChange}></input>
                            <button type="submit"><img src="../imgs/search-iqon.png" class="searchIqon"></img></button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="searchTitle">
                    <div className="count">{searchResults.length}</div> نتيجة بحث
                </div>
                <div className="searchRes">
                    {searchResults.map((item) => (
                        <div className="result" key={item?.id}>
                            {item.section ? (
                                <div className="result" key={item?.id}>
                                    <div className="tit"><Link to={`/research/${item?._id}`}>{parse(`${item?.title}`)}</Link></div>
                                    <div className="sec">
                                        <div className="gray">القسم: </div>
                                        {parse(`${item?.section}`)}
                                    </div>
                                    <div className="date">
                                        <div className="gray">تاريخ النشر:</div>
                                        {parse(`${item?.publishingdate}`)}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="artTit"><Link to={`/article/${item?._id}`}>{parse(`${item?.title}`)}</Link></div>
                                    <div className="artDate">{moment(item?.date).format('LL')}</div>
                                    <div className="artBrief">{parse(`${item?.summary}`)}</div>
                                </div>)}
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div >
    )
}